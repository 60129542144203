<template>
    <div>
        <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
        </el-data-table>

        <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
            <el-form :model="form" :rules="rules" ref="ruleUser">
                <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
                    <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
                </el-form-item>
                <el-form-item label='风控名称' prop='riskname' :label-width="formLabelWidth" v-if="type=='insert'">
                    <el-input placeholder='请输入风控名称' clearable autocomplete="off" v-model='form.riskname' size="small"  />
                </el-form-item>
                <el-form-item label='风控内容' prop='content' :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="4" placeholder='请输入风控内容' clearable autocomplete="off" v-model='form.content' size="small"  />
                </el-form-item>
                <el-form-item label='风控小时' prop='riskhours' :label-width="formLabelWidth">
                    <el-input-number placeholder='请输入风控小时' clearable autocomplete="off" v-model='form.riskhours' :precision="0" :step="1" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label='是否永久封禁' prop='isforever' :label-width="formLabelWidth">
                    <el-select v-model="form.isforever" placeholder="请选择">
                        <el-option
                                v-for="item in isforeverOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog()">取 消</el-button>
                <el-button type="primary" @click="submitUser">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { insertRiskcontent,updateRiskcontent,deleteRiskcontent,bacthDelRiskcontent } from "@/request/requests";
    import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
    export default {
        name: "user",
        data(){
            const isforeverOptions=[
                {
                    value:"1",
                    label:"是"
                },
                {
                    value:"0",
                    label:"否"
                },
            ]
            const columns = [
                {type: 'selection'},
                {prop: 'id',label: '序号'},
                {prop: 'riskname',label: '风控名称'},
                {prop: 'content',label: '风控内容'},
                {prop: 'riskhours',label: '风控小时'},
                {prop: 'isforever',label: '是否永久封禁',formatter: row => this.isforeverState(row)},
                {prop: 'createTime',label: '创建时间'},
                {prop: 'updateTime',label: '更新时间'},
            ]
            const searchForm = [
                {
                    type: 'input',
                    id: 'riskname',
                    label: '风控名称',
                    el: {placeholder: '请输入风控名称', clearable: true},
                },
                {
                    type: 'input',
                    id: 'content',
                    label: '风控内容',
                    el: {placeholder: '请输入风控内容', clearable: true},
                },
                {
                    type: 'select',
                    id: 'isforever',
                    label: '是否永久封禁',
                    el: {placeholder: '请输入是否永久封禁', clearable: true},
                    options:isforeverOptions
                },
            ]
            return {
                columns,
                isforeverOptions,
                userinfo:{},
                tableConfig: {
                    searchForm,
                    url: 'riskcontent/list',
                    dataPath: 'data.list',
                    totalPath: 'data.total',
                    paginationSize:10,
                    extraButtons: [
                        {
                            type: 'primary',
                            text: '编辑',
                            icon: 'el-icon-edit-outline',
                            show:()=>{return this.hasRole('riskcontent:update')},
                            atClick: async row => {
                                this.handleEdit(row)
                                return false
                            },
                        },
                        {
                            type: 'primary',
                            text: '删除',
                            icon: 'el-icon-delete',
                            show:()=>{return this.hasRole('riskcontent:delete')},
                            atClick: async row => {
                                this.handleDelete(row)
                                return false
                            },
                        },
                    ],
                    headerButtons: [
                        {
                            text: '新增',
                            type: 'primary',
                            show:()=>{return this.hasRole('riskcontent:insert')},
                            atClick: async () => {
                                this.handleInsert()
                                return false
                            },
                        },
                        {
                            text: '批量删除',
                            type: 'primary',
                            show:()=>{return this.hasRole('riskcontent:batchDelete')},
                            disabled: selected => selected.length < 1,
                            atClick: selected => {
                                let ids = selected.map(item => item.id)
                                this.Batch('delete', ids)
                                return false
                            },
                        },
                    ],
                    hasDelete: false,
                    hasEdit: false,
                    hasOperation: true,
                    hasNew: false,
                    operationAttrs: {
                        width: '150px',
                        fixed: 'right',
                    },
                },
                form:this.initForm(),
                title:"",
                type:"",
                dialogFormVisible:false,
                formLabelWidth:"110px",
                rules: {
                    id :[
                        {required: true, message: '请输入序号', trigger: 'blur'},],
                    riskname :[
                        {required: true, message: '请输入风控名称', trigger: 'blur'},],
                    content :[
                        {required: true, message: '请输入风控内容', trigger: 'blur'},],
                    riskhours :[
                        {required: true, message: '请输入风控小时', trigger: 'blur'},],
                    isforever :[
                        {required: true, message: '请输入是否永久封禁  1:是 0:否', trigger: 'blur'},],
                    createTime :[
                        {required: true, message: '请输入创建时间', trigger: 'blur'},],
                    updateTime :[
                        {required: true, message: '请输入更新时间', trigger: 'blur'},],
                    ext1 :[
                        {required: true, message: '请输入扩展字段', trigger: 'blur'},],
                    ext2 :[
                        {required: true, message: '请输入扩展字段', trigger: 'blur'},],
                    ext3 :[
                        {required: true, message: '请输入扩展字段', trigger: 'blur'},],
                    ext4 :[
                        {required: true, message: '请输入扩展字段', trigger: 'blur'},],
                },
            }
        },
        methods:{
            isforeverState(row){
                let data
                this.isforeverOptions.forEach(r=>{
                    if (r.value==row.isforever){
                        data=r.label
                    }
                })
                return data
            },
            hasRole(val){
                return this.hasPerm(val)
            },
            initForm() {//初始数据
                return {
                    id : "", // 序号
                    riskname : "", // 风控名称
                    content : "", // 风控内容
                    riskhours : "", // 风控小时
                    isforever : "", // 是否永久封禁  1:是 0:否
                    createTime : "", // 创建时间
                    updateTime : "", // 更新时间
                    ext1 : "", // 扩展字段
                    ext2 : "", // 扩展字段
                    ext3 : "", // 扩展字段
                    ext4 : "", // 扩展字段
                }
            },
            handleEdit(row) {
                console.log(row)
                this.form = row
                this.openDialog();
                this.title = "修改信息"
                this.type = "update"
            },
            handleDelete(row) {
                this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.operatorDeleteUser(row)
                }).catch(() => {
                    MessageInfo("已取消删除");
                });
            },
            async operatorDeleteUser(row) {
                let res = await deleteRiskcontent(row.id)
                if (res.status == "200") {
                    MessageSuccess(res.msg)
                } else {
                    MessageError(res.msg)
                }
                this.$refs.table.getList()
            },
            handleInsert() {
                this.openDialog();
                this.title = "新增"
                this.type = "insert"
            },
            async Batch(type, ids) {
                if (type == "delete") {
                    let res = await bacthDelRiskcontent(ids)
                    if (res.status == "200") {
                        MessageSuccess(res.msg)
                    } else {
                        MessageError(res.msg)
                    }
                }
                this.$refs.table.getList()
            },
            openDialog() {
                this.dialogFormVisible = true
            },
            closeDialog() {
                this.dialogFormVisible = false
                this.form = this.initForm();
                this.$refs.ruleUser.clearValidate();
                this.$refs.table.getList()
            },
            async submitUser() {
                this.$refs.ruleUser.validate((valid) => {
                    if (valid) {
                        this.operatorUser()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async operatorUser() {
                let res;
                if (this.type == "insert") {
                    res = await insertRiskcontent(this.form)
                    if (res.status == "200") {
                        MessageSuccess(res.msg)
                        this.closeDialog()
                    } else {
                        MessageError(res.msg)
                    }
                } else if (this.type == "update") {
                    res = await updateRiskcontent(this.form)
                    if (res.status == "200") {
                        MessageSuccess(res.msg)
                        this.closeDialog()
                    } else {
                        MessageError(res.msg)
                    }
                }
                this.$refs.table.getList()
            },getUserInfo(){
                let deadline = sessionStorage.getItem('deadline');
                let userInfo = sessionStorage.getItem("userInfo");
                let token = sessionStorage.getItem('token');
                if(!deadline||!userInfo||!token) {
                    this.removeInfo()
                    MessageError("请先登录！")
                    this.$router.push({ path: "/login" });
                    return false
                }else{
                    let now = new Date().getTime();
                    if (deadline<now){
                        this.removeInfo()
                        MessageError("请先登录！")
                        this.$router.push({ path: "/login" });
                        return false
                    }
                }
                return userInfo;
            },
            removeInfo(){
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("deadline");
                sessionStorage.removeItem("token");
            }
        },
        created() {
            let userInfo=this.getUserInfo()
            if (userInfo){
                this.userinfo=JSON.parse(userInfo)
                this.tableConfig.axiosConfig={
                    headers: {
                        'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token')
                    }
                }
                if (!this.hasRole('riskcontent:update')&&!this.hasRole('riskcontent:delete')){
                    this.tableConfig.hasOperation=false
                }
            }
        }
    }
</script>

<style scoped>

</style>
